import React from 'react';
import IconButton from '../UI/IconButton/IconButton.js';
import styles from './Speciality.module.scss';

const Speciality = ({ title, desc, link, img }) => {
  return (
    <article className={styles.speciality}>
      <div className={styles.body}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{desc}</p>
        {link &&
          <IconButton className={styles.link} to={link} label="Learn more" isReverse={true}/>
        }
      </div>
      
      {img &&
        <div className={styles.img}>
          <img src={img.publicURL || img} alt="" loading="lazy"/>
        </div>
      }
    </article>
  )
}

Speciality.propTypes = {
  
};

export default Speciality;
