import React from 'react';
import { graphql } from 'gatsby';
import { v4 } from 'uuid';
import { themes } from "../context/ThemeContext";
import Layout from '../components/Layout';
import SectionRenderer from '../components/SectionRenderer/SectionRenderer';
import Section from '../components/Section/Section';
import Hgroup from '../components/UI/Hgroup/Hgroup';
import Speciality from '../components/Speciality/Speciality';
import Service from '../components/Service/Service';

import ButtonLink from '../components/UI/ButtonLink/ButtonLink';
import { PanelGroup } from '../components/UI/Panel/Panel';
import parseMarkdown from '../utils/parseMarkdown';

import type from '../styles/type.module.scss';
import utilities from '../styles/utilities.module.scss';

export const WhatWeDoPageTemplate = ({
    name,
    title,
    specialities,
    services,
    sections
}) => {
    return (
        <>
            <Section Tag="header" theme={themes.LIGHT} isCompact={true}>
                <Hgroup lead={name} title={title}/>
            </Section>

            <Section theme={themes.DARK} isUnstyled={true}>
                <PanelGroup isFullWidth={true} isPrimary={true} cols={{ md: 6 }}>
                    {specialities.map(speciality =>
                        <Speciality {...speciality} key={v4()} />
                    )}
                </PanelGroup>
            </Section>

            <Section theme={themes.LIGHT} isUnstyled={true}>
                <PanelGroup isFullWidth={true} cols={{ md: 6, lg: 3 }}>
                    {services.map(service =>
                        <Service {...service} key={v4()} />
                    )}
                </PanelGroup>
            </Section>

            <Section className={utilities.textCenter} isCompact={true} theme={themes.LIGHT}>
                <h2 className={type.h4}>Get inspired</h2>
                <ButtonLink to={`/work/`}>View our latest projects</ButtonLink>
            </Section>

            <SectionRenderer data={parseMarkdown(sections)} />
        </>
    )
};

const WhatWeDoPage = ({ data: { page } }) => {
    return (
        <Layout 
            meta={page.frontmatter.meta || false}
            title={page.frontmatter.name || false}
            theme={themes.LIGHT}
        >
            <WhatWeDoPageTemplate {...parseMarkdown(page)}/>
        </Layout>
    )
};

export default WhatWeDoPage;

export const pageQuery = graphql`
  ## Query for WhatWeDoPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query WhatWeDoPageTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      ...Sections
      ...Pages
      frontmatter {
        name
        title
        specialities {
          title
          desc
          link
          img {
            publicURL
            extension
          }
        }
        services {
          title
          items
          link
        }
      }
    }
  }
`
