import React from 'react';
import { v4 } from 'uuid'; 
import IconButton from '../UI/IconButton/IconButton.js';
import styles from './Service.module.scss';

const Service = ({ title, items, link }) => {
  return (
    <article className={styles.service}>
      <h2 className={styles.title}>{title}</h2>
      {items && 
        <ul className={styles.list}>
          {items.map( item => 
            <li key={v4()}>{item}</li>
          )}
        </ul>
      }
      
      {link &&
        <IconButton className={styles.link} to={link} label="Learn more"/>
      }
    </article>
  )
}

Service.propTypes = {
  
};

export default Service;
