import React from 'react';
import classNames from 'classnames';
import Link from '../Link/Link';
import Icon from '../Icon/Icon.js';
import styles from './IconButton.module.scss';

const IconButton = ({ 
    className,
    label, 
    iconName, 
    isReverse, 
    isPrimary, 
    onClick, 
    to, 
    ...other
}) => {
    const btnClassName = classNames( styles.button, {
        [styles.isReverse]: isReverse
    }, className);
    
    return onClick ? (
        <button className={btnClassName} type="button" onClick={onClick} aria-label={label} {...other}>
            <Icon className={styles.icon} name={iconName}/>
        </button>
    ) : (
        <Link className={btnClassName} to={to} aria-label={label} {...other}>
            <Icon className={styles.icon} name={iconName}/>
        </Link>
    )
}

IconButton.defaultProps = {
    iconName: 'arrow-right'
}
export default IconButton;